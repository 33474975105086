<template>
  <div style="width: 100%">
    <v-overlay :value="loading" z-index=10 class="text-center">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
      <div class="pt-15">{{ loading_message }}</div>
    </v-overlay>
    <v-snackbar
      v-model="no_contacts"
      timeout= -1
      color="error"
      bottom
    >
      No hay contactos disponibles para esta unidad.
    </v-snackbar>
    <v-row no-gutters>
      <v-col cols="auto" v-if="validBuilding && validLocation && validDistance">
        <v-navigation-drawer
          width="200"
          permanent
          height="100vh"
          :color="whiteLabel.colors.primary"
          dark
          :mini-variant="mini"
          mini-variant-width="140"
        >
          <v-list nav>
            <v-list-item-group color="white">
              <template v-for="(item,index) in parsed_building.floors">
                <v-list-item
                  :key="item.id"
                  @click="showApartments(item.id)"
                >
                  <v-list-item-content class="text-center text-uppercase">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index < parsed_building.floors.length - 1" :key="`${index}-divider`" class="pb-2"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col class="scroll-height">
        <v-main>
          <v-container>

            <v-alert
              text color="red lighten-1" outlined prominent v-if="retryLocation" type="error"
            >
              <h3 class="text-h5">
                Su ubicación no pudo ser establecida
              </h3>
              <div class="pt-4">Se agotó el tiempo para establecer su ubicación.<br>
              Asegúrese de tener los servicios de localización habilitados y de haber permitido el uso de la localización cuando le fue requerido.</div>
              <v-divider
                class="my-4 red"
              ></v-divider>
              <v-btn
                color="primary" outlined elevation="2" large @click="getLocation"
              >
                <v-icon dark class="pr-4">
                  mdi-crosshairs-gps
                </v-icon>
                Reintentar
              </v-btn>
            </v-alert>

            <v-alert
              text color="red lighten-1" outlined prominent v-if="!validBuilding && !validQR" type="error"
            >
              <h3 class="text-h5">
                QR inválido
              </h3>
              <div class="pt-4">
                El QR no pudo ser leído correctamente o contiene información inválida.<br>Por favor, vuelva a escanear el QR.</div>
              <v-divider
                class="my-4 red"
              ></v-divider>
              <div>
                Si el problema persiste contáctese con la administración o con <a href="mailto:info@tappbell.com">TappBell</a>
              </div>
            </v-alert>

            <v-alert
              text color="red lighten-1" outlined prominent v-if="validBuilding && !validDistance" type="error"
            >
              <h3 class="text-h5">
                Distancia al QR inválida
              </h3>
              <div class="pt-4">
                Su ubicación actual se encuentra a una distancia mayor a la máxima permitida con el frente TappBell Virtual.<br>
                Por favor, acérquese y vuelva a escanear el QR.<br><br>
                Distancia al frente ~ {{distance}} mts. Distancia máxima {{this.qr_location.range}} mts.
              </div>
              <v-divider
                class="my-4 red"
              ></v-divider>
              <div>
                Si el problema persiste contáctese con la administración o con <a href="mailto:info@tappbell.com">TappBell</a>
              </div>
            </v-alert>
            
            <v-row v-if="floor_selected && validDistance">
              <v-col
                v-for="item in apartments"
                :key="item.id"
                md="4"
                sm="6"
                cols="12"
              >
                <v-btn
                block
                color="grey lighten-2"
                large
                class="text-center text-uppercase"
                @click="retrieveContactsFromApartment(item.id, item.name)">
                  {{ item.name }}
                </v-btn>
              </v-col>
            </v-row>

            <div v-if="validBuilding && validLocation && validDistance && !floor_selected" class="text-center">
              <h2 class="text-center pb-4" color="primary">Seleccione planta,<br> luego unidad</h2>
              <v-img
                max-width="250"
                :src="image"
                style="margin: auto;"
              ></v-img>
            </div>

          </v-container>
        </v-main>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../store";

export default {

  data: () => ({
    floor_selected: null,
    image: require('@/assets/seleccione-planta-unidad.png'),
    apartments: [],
    current_location: {
      lat: null,
      lng: null,
    },
    validLocation: false,
    errorMessage: null,
    retryLocation: false,
    loading: true,
    loading_message: null,
    validBuilding: false,
    validQR: true,
    distance: null,
    validDistance: true,
    no_contacts: false,
  }),

  created() {},

  mounted() {
    this.getBuilding(this.$route.params.virtual);
  },

  computed: {
    ...mapGetters({
      building: "building",
      parsed_building: "parsed_building",
      qr_location: "qr_location",
      current_contacts: "current_contacts",
      cc_data: "cc_data",
      whiteLabel: "whiteLabel",
    }),
    mini() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  
  methods: {
    async getBuilding(QRId) {
      this.loading_message = 'Analizando QR ...';
      this.loading = true;
      await store.dispatch("fetchBuilding", QRId);
      if (this.building) {
        this.validBuilding = true;
        this.validQR = true;
        if (this.parsed_building.floors) {
            var sap = null;
            if (this.$route.params.floor) {
                for (var i=0;i<this.parsed_building.floors.length;i++) {
                    if (this.parsed_building.floors[i].id==this.$route.params.floor) {
                        sap = this.parsed_building.floors[i].id;
                    }
                }
            }
            else {
                if (this.parsed_building.floors.length==1) {
                    sap = this.parsed_building.floors[0].id;
                }
            }
            if (sap) {
                this.showApartments(sap);
            }
        }
        await this.getLocation();
      } else {
        this.validBuilding = false;
        this.validQR = false;
      }
      this.loading = false;
    },
    
    async getLocation() {
      try {
        if (this.qr_location.range > 0) {
          this.validLocation = false;
          this.retryLocation = false;
          this.loading_message = 'Obteniendo ubicación ...';
          this.loading = true;
          await this.$getLocation({enableHighAccuracy: true,timeout: 4000, maximumAge: 0})
            .then(coordinates => {
              this.current_location = coordinates;
              this.errorMessage = null;
              this.loading = false;
              this.distance = Math.round(this.getDistance(this.current_location.lat, this.current_location.lng, this.qr_location.lat, this.qr_location.lng));
              console.log('Distancia entre el dispositivo y el QR', this.distance, 'metros');
              if (this.distance <= this.qr_location.range) {
                this.validDistance= true;
                this.validLocation = true;
              } else {
                this.validDistance = false;
              }
            })
            .catch(error => {
              this.retryLocation = true;
              this.errorMessage = error;
              this.validLocation = false;
              this.loading = false;
            });
        } else {
          this.validDistance= true;
          this.validLocation = true;
          console.log('Distancia entre el dispositivo y el QR ilimitada');
        }

      } catch (error) {
        this.loading = false;
      }
    },

    showApartments(floor) {
      this.no_contacts = false;
      let f = this.parsed_building.floors.find((f) => f.id == floor);
      this.floor_selected = f;
      this.apartments = f.apartments;
    },

    async retrieveContactsFromApartment(apartment, apartment_name) {
      this.$emit('lring');
      this.no_contacts = false;

      await this.getLocation();
      if (!this.validLocation) {
        return;
      }

      this.loading_message = 'Estableciendo videollamada ...';
      this.loading = true;
      await store.dispatch("fetchContacts", {QRId:this.$route.params.virtual,floorId:this.floor_selected.id,apartmentId:apartment});
      if (this.current_contacts && this.cc_data) {
        if (Object.keys(this.current_contacts).length>0) {
          this.$router.push({ name: 'call', params: {
            QRId: this.$route.params.virtual,
            building: (this.building.name.length>0 ? this.building.name + " - " : "") + this.building.address,
            doors: this.building.settings.door1_dvcid ? (this.building.settings.door2_dvcid ? (this.building.settings.door3_dvcid ? 3 : 2) : 1) : 0,
            call_time: this.building.settings.call_time,
            floor: this.floor_selected.id,
            apartment: apartment,
            contacts: this.current_contacts,
            cc_data: this.cc_data,
            calling_to: this.floor_selected.name + ' - ' + apartment_name,
          } });
        }
        else {
          console.log('Sin contactos vinculados');
          this.no_contacts = true;
        }
      }
      else {
        this.no_contacts = true;
      }
      this.loading = false;
    },

    getDistance(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);
      var dLon = this.deg2rad(lon2-lon1);
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c * 1000; // Distance in meters
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI/180);
    },

  },
};
</script>

<style lang="scss" scoped>
.v-list-item--active {
  &::after {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 25px solid white;
    position: absolute;
    right: -25px;
  }
}
.theme--dark.v-navigation-drawer .v-divider {
	border-color: white !important;
}
</style>
